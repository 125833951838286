import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Announcement } from '../../../common/gql/graphql';

@Component({
    selector: 'kb-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class AnnouncementComponent {
    @Input() announcement: Pick<Announcement, 'content' | 'backgroundColor' | 'textColor' | 'scope'>;
    constructor(private sanitizer: DomSanitizer) {}

    sanitize(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
